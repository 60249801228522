import React, { Component } from 'react';
import { Button, Navbar, Nav, NavDropdown, Container, Card, Row, Col } from 'react-bootstrap';
import image1 from './images/1.webp';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class articles extends Component {

    state = {
        catVideos: [],
        topVideo: []
    }

    componentDidMount() {

        axios.get('https://app.realtywired.show/public/api/v1/videos/by_type/2').then(res => {
            this.setState({ topVideo: res.data[0] });
            //  this.setState({});
            // console.log(this.state.videos)
        });
        axios.get('https://app.realtywired.show/public/api/v1/videos/latest').then(res => {
            this.setState({ catVideos: res.data });
            console.log(res.data)
        })
    }

    render() {
        return (
            <>

                <Helmet>
                    <title>RealtyWired|Home</title>
                </Helmet>
                <Container className="mt-5">
                    <h1>Most Recent</h1>

                    <Card className="card" key={this.state.topVideo.id}>
                        <Card.Img variant="top" src={this.state.topVideo.thumbnail} />
                        <Card.Body>
                            <Link to={'/article/' + this.state.topVideo.id}>
                                <Card.Title>{this.state.topVideo.title}</Card.Title>
                            </Link>

                        </Card.Body>
                    </Card>
                    <div className="divider"></div>

                </Container>
                <Container className="mt-5">
                    {
                        this.state.catVideos.map((catVideo, index) => {

                            return <div className="wrap">
                                <h1 className="mt-4">{catVideo.category.name}</h1>
                                <Row className="mt-4">
                                    <Col key={catVideo.videos[0].id}>
                                        <Card className="card">
                                            <Card.Img variant="top" src={catVideo.videos[0].thumbnail} />
                                            <Card.Body>
                                                <Link to={'/article/' + catVideo.videos[0].id}>
                                                    <Card.Title>{catVideo.videos[0].title}</Card.Title>
                                                </Link>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mt-4 d-flex flex-row flex-wrap">
                                    {

                                        catVideo.videos.map((video, index2) => {
                                            // console.log(video)
                                            if (index2 != 0) {
                                                return <Col md={6} className="mt-4 d-flex flex-grow-1" key={video.id}>

                                                    <Card className="card d-flex flex-column flex-grow-1">
                                                        <Card.Img variant="top" className="flex-grow-1" src={video.thumbnail} />
                                                        <Card.Body>
                                                            <Link to={'/article/' + video.id}>
                                                                <Card.Title>{video.title}</Card.Title>
                                                            </Link>

                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            }//for videos
                                        })
                                    }

                                </Row>
                                <div className="divider"></div>
                            </div>
                        })
                    }


                </Container>




            </>

        );//return
    }//render
}//class


export default articles 