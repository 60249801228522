import React, { Component } from 'react';
import { Button, Navbar, Nav, NavDropdown, Container, Card, Row, Col } from 'react-bootstrap';
import image1 from './images/1.webp';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class catwisewrticles extends Component {

    state = {
        catVideos: [],
        catName: ""
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        // console.log(id);
        axios.get('https://app.realtywired.show/public/api/v1/videos/by_category/' + id).then(res => {
            this.setState({ catVideos: res.data.data });
        }).then(() => {
            axios.get('https://app.realtywired.show/public/api/v1/categories/' + id).then(res => {
                let catname = res.data.name;
                this.setState({ catName: catname });
                // console.log(this.state.catVideos);
            });
        });
    }
    componentWillReceiveProps(currentProps, prevState) {
        let id = currentProps.match.params.id;
        // console.log(id);
        axios.get('https://app.realtywired.show/public/api/v1/videos/by_category/' + id).then(res => {
            this.setState({ catVideos: res.data.data });
        }).then(() => {
            axios.get('https://app.realtywired.show/public/api/v1/categories/' + id).then(res => {
                let catname = res.data.name;
                this.setState({ catName: catname });
                // console.log(this.state.catVideos);
            });
        });

    }

    render() {
        return (
            <>
                <Helmet>
                    <title>RealtyWired|{this.state.catName}</title>
                </Helmet>


                <Container className="mt-5">
                    <h1>{this.state.catName}</h1>
                    <Row className="mt-4 d-flex flex-row flex-wrap">
                        {
                            this.state.catVideos.map((catVideo, index) => {

                                if (index == 0) {
                                    return <Col md={12} key={catVideo.id} className="mt-5">
                                            <Card className="card">
                                                <Card.Img variant="top" src={catVideo.thumbnail} />
                                                <Card.Body>
                                                <Link to={'/article/' + catVideo.id}>
                                                    <Card.Title>{catVideo.title}</Card.Title>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                    </Col>
                                } else {
                                    return <Col md={6} key={catVideo.id} className="mt-5 d-flex flex-grow-1">

                                        <Card className="card d-flex flex-column flex-grow-1">
                                            <Card.Img variant="top" className="flex-grow-1" src={catVideo.thumbnail} />
                                            <Card.Body>
                                                <Link to={'/article/' + catVideo.id}>
                                                    <Card.Title>{catVideo.title}</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>


                                }

                            })
                        }
                    </Row>

                </Container>

            </>

        );//return
    }//render
}//class


export default catwisewrticles 