import React, { Component } from "react";
import axios from "axios";
import { Button, Navbar, Nav, NavDropdown, Container, Card, Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";
class article extends Component {

    state = {
        topVideo: {}
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        axios.get('https://app.realtywired.show/public/api/v1/videos/' + id).then(res => {
            // console.log(res.data);
            this.setState({ topVideo: res.data });
            // console.log(this.state.topVideo);
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.topVideo.title}</title>
                    <meta property="og:url" content={"https://staging.realtywired.show/article/"+this.state.topVideo.id}/>
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={this.state.topVideo.title} />
                    <meta property="og:description" content={this.state.topVideo.description} />
                    <meta property="og:image" content={"https://app.realtywired.show/public/uploads/" + this.state.topVideo.thumbnail}/>
                </Helmet>


                <Container className="mt-5 min-height-100vh">
                    <Card >

                        <Card.Img variant="top" src={"https://app.realtywired.show/public/uploads/" + this.state.topVideo.thumbnail} />
                        <Card.Body>
                            <Card.Title>{this.state.topVideo.title}</Card.Title>
                            <Card.Text>
                                {this.state.topVideo.description}
                            </Card.Text>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                        </Card.Body>
                    </Card>
                </Container>

            </>
        );
    }
}//article class

export default article