import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, withRouter } from "react-router-dom";

import Articles from "./articles/articles";
import Article from "./articles/article";
import Navbar from "./articles/navbar";
import catwisewrticles from "./articles/catwisewrticles";
import Footer from "./articles/footer";
import PrivacyPolicy from "./articles/privacyPolicy";


function App() {
  return (    
    <div className="App">
      
        <BrowserRouter>
        <Navbar/>
          <Route exact path={'/'} component={Articles}/>
          <Route path={'/article/:id'} component={Article}/>
          <Route path={'/category/:id'} component={catwisewrticles}/>
          <Route path={'/privacy-policy'} component={PrivacyPolicy}/>
        
        <Footer/>
        </BrowserRouter>

    </div>
  );
}

export default App;
