import React,{ Component } from 'react';
import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import realtywired from "./images/realtywired.png";
import axios from 'axios';
class navbar extends Component {

    state={
        categories:[]
    }
    componentDidMount(){
        axios.get('https://app.realtywired.show/public/api/v1/videos/latest').then(res => {
            this.setState({ categories: res.data });
            // console.log(res.data)
        })
    }

    render(){
    return (
        <>
            <Navbar expand="lg">
            <Link className = "navbar-brand" to = {'/'}><img className="site_logo" src = {realtywired}/></Link>
                {/* <Navbar.Brand>RealtyWired</Navbar.Brand> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        {
                            this.state.categories.map(category=>{
                                // console.log(category.category)
                                return <Link className = "nav-link" key={category.category.id} to = {"/category/"+category.category.id}>{category.category.name}</Link>
                            })
                        }
                        {/* <Nav.Link href="#link">REO</Nav.Link>
                        <Nav.Link href="#link">Videos</Nav.Link>
                        <Nav.Link href="#link">RE News</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        </>
    );
    }
}

export default navbar 