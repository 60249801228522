import react,{ Component } from "react";
import { Button, Navbar, Nav, NavDropdown, Container, Card, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

class footer extends Component{

    render(){
        return(
            <>
                <footer className = "bg-danger p-5 mt-5">
                    <Container className="text-center text-white">
                        <h6>&copy;2021 RealtyWired.show all rights reserved | <Link to={"/privacy-policy"}>Privacy policy</Link></h6>
                    </Container>
                </footer>
            </>
        )
    }

}
export default footer;